import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { getSyncCall, postSyncCall } from "../utils/apiAdapter";
import moment from "moment";
import { apiSource } from "../constants/dashboardConstants";

export const initialState = {
  flag: false,
  sidePanel: false,
  pageSize: 10,
  failedOrdersSearchData: {},
  failedOrdersRequestData: {},
  failedOrdersResponseData: {
    success: [],
    status: "idle",
    error: [],
  },
  getorderReprocessSubmitRequestData: {
    orderCollection: [],
  },
  getorderReprocessSubmitResponseData: {},
  setorderReprocessSubmitResponseData: {},
  showOrHideSnackBar: false,
  snackBarMessage: "",
};

export const getFailedOrderDetails = createAsyncThunk(
  "reprocessOrdersSlice/getFailedOrderDetails",
  (input) => {
    try {
      //const api ="https://microcks.mp-ordermgmt-test.nikecloud.com:8080/rest/STO+Pipeline/1.0.0/order_mgmt/osd_stale_orders/v3/"
      const api = `${apiSource}/order_mgmt/osd_orders/v3/`; // QA2
      // const res = getSyncCall(api, {headers},state.orderSummaryRequestData)
      // const headers= {
      //     'Authorization' : input["authKey"]
      // }
      // let reqParams = {
      //         'filter' : input['data']
      // }

      //const res = getSyncCall(api, {headers},state.orderSummaryRequestData)
      const headers = {
        //'Authorization' : input["authKey"]
      };
      let reqParams =
        "filter=" +
        input["data"] +
        "&count=" +
        input["count"] +
        "&anchor=" +
        input["page"];
      const res = getSyncCall(api, headers, reqParams);
      return res;
    } catch (e) {
      console.log(e);
    }
    // if(state.getorderReprocessSubmitRequestData && state.getorderReprocessSubmitRequestData.filter ) {
    //     const response = getSyncCall(failedOrderDetailsURL, { headers }, state.getorderReprocessSubmitRequestData );
    //     return response;

    // }
  }
);

export const postFailedOrderReprocess1 = createAsyncThunk(
  "reprocessOrdersSlice/postFailedOrderReprocess1",
  (input, headers) => {
    //const postOrderReprocessingURL = baseConfig.api.orderReprocessingApi
    try {
      const api =
        "https://microcks.mp-ordermgmt-test.nikecloud.com:8080/rest/STO+Pipeline/1.0.0/order_mgmt/osd_stale_orders/v3/process";
      const res = postSyncCall(api, input, null);
      return res;
    } catch (e) {
      console.log(e);
    }
    // if(state.failedOrdersRequestData && state.failedOrdersRequestData.orderIds) {
    //     const response = postSyncCall(postOrderReprocessingURL, state.failedOrdersRequestData, { headers });
    //     return response;
    // }
  }
);
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const postFailedOrderReprocess = createAsyncThunk(
  "reprocessOrdersSlice/postFailedOrderReprocess",

  async (_, { getState, dispatch }) => {
    const rootState = getState(); // has type YourRootState
    //const api ="https://microcks.mp-ordermgmt-test.nikecloud.com:8080/rest/STO+Pipeline/1.0.0/order_mgmt/osd_stale_orders/v3/process"
    const api = `${apiSource}/order_mgmt/osd_orders/v3/process`;
    const input = rootState.reprocessReducer.failedOrdersSearchData;
    const res = await postSyncCall(
      api,
      rootState.reprocessReducer.failedOrdersRequestData,
      null
    );
    if (res["success"].length) {
      dispatch(setFlag(true));
      dispatch(
        getFailedOrderDetails(rootState.reprocessReducer.failedOrdersSearchData)
      );
    }

    return res;
  }
);

const reprocessOrdersApiSlice = createSlice({
  name: "reprocessOrdersApiSlice",
  initialState,
  extraReducers: {
    [getFailedOrderDetails.pending]() {},
    [getFailedOrderDetails.fulfilled](state, { payload }) {
      state.getorderReprocessSubmitResponseData = { ...payload };
    },
    [getFailedOrderDetails.rejected](state) {
      console.log("fetching Errores");
      state.error = "Rejected";
    },
    [postFailedOrderReprocess.pending]() {
      console.log("pending");
    },
    [postFailedOrderReprocess.fulfilled](state, action) {
      state.failedOrdersResponseData = action.payload;
    },
    [postFailedOrderReprocess.rejected](state) {
      state.error = "Rejected";
    },
  },
  reducers: {
    resetSearchData(state) {
      // return initialState.failedOrdersRequestData;
      state.failedOrdersRequestData = {};
      state.pageSize = 10;
      state.getorderReprocessSubmitResponseData = {};
    },
    resetFailedOrderResponseData(state) {
      state.getorderReprocessSubmitResponseData = {};
    },
    setFlag(state, flag) {
      state.flag = flag;
    },
    setSidePanel(state, data) {
      state.sidePanel = data.payload;
    },
    setPageSize(state, pageSize) {
      state.pageSize = pageSize["payload"];
    },
    getFailedOrderSearchData(state) {
      return state.failedOrdersSearchData;
    },
    setFailedOrderRequestData(state, requestedData) {
      state.failedOrdersRequestData = requestedData["payload"];
    },
    getFailedOrderRequestData(state) {
      return state.failedOrdersRequestData;
    },
    setFailedOrderSearchData(state, requestedData) {
      state.failedOrdersSearchData = {
        data: requestedData["payload"]["data"],
        page: requestedData["payload"]["page"],
        count: requestedData["payload"]["count"],
      };
    },
    updateFailedOrdersResponseData(state, requestedData) {
      console.log(current(state));
      let currentState = current(state);
      const failedOrderRequestDataList =
        currentState.failedOrdersRequestData && [
          ...currentState.failedOrdersRequestData,
        ];
      let responseData = currentState.getorderReprocessSubmitResponseData && [
        ...currentState.getorderReprocessSubmitResponseData.orderCollection,
      ];
      const newReponseData = responseData.map((order) => {
        if (
          failedOrderRequestDataList &&
          failedOrderRequestDataList.includes(order.orderId)
        ) {
          return {
            ...order,
            comments: requestedData["payload"].split("|")[0],
            defectId: requestedData["payload"].split("|")[1],
          };
        }
        return order;
      });

      return {
        ...state,
        getorderReprocessSubmitResponseData: {
          ...state.getorderReprocessSubmitResponseData,
          orderCollection: newReponseData,
        },
      };
    },

    updateFormSearchData(state, targetValue) {
      const inputValue = targetValue.payload;
      if (inputValue.split("|")[0] === "orderId") {
        return {
          ...state,
          failedOrdersSearchData: {
            ...state.failedOrdersSearchData,
            orderId: inputValue.split("|")[1],
          },
        };
      } else if (inputValue.split("|")[0] === "customerPONumber") {
        return {
          ...state,
          failedOrdersSearchData: {
            ...state.failedOrdersSearchData,
            poNumber: inputValue.split("|")[1],
          },
        };
      } else if (
        inputValue.split("|")[0] === "orderType" &&
        inputValue.split("|")[0] !== "Select Order Type"
      ) {
        return {
          ...state,
          failedOrdersSearchData: {
            ...state.failedOrdersSearchData,
            orderType: inputValue.split("|")[1],
          },
        };
      } else if (
        inputValue.split("|")[0] === "orderStatus" &&
        inputValue.split("|")[0] !== "Select Status"
      ) {
        return {
          ...state,
          failedOrdersSearchData: {
            ...state.failedOrdersSearchData,
            orderStatus: inputValue.split("|")[1],
          },
        };
      } else if (presetDates.includes(inputValue.split("|")[0])) {
        return {
          ...state,
          failedOrdersSearchData: {
            ...state.failedOrdersSearchData,
            createdOnAfter: calculateDate(
              inputValue.split("|")[0],
              inputValue.split("|")[1]
            ),
            createdOnBefore: new Date(),
          },
        };
      } else if (inputValue === "fromDate") {
        return {
          ...state,
          failedOrdersSearchData: {
            ...state.failedOrdersSearchData,
            createdOnAfter: inputValue.split("|")[1],
          },
        };
      } else if (inputValue === "toDate") {
        return {
          ...state,
          failedOrdersSearchData: {
            ...state.failedOrdersSearchData,
            createdOnBefore: inputValue.split("|")[1],
          },
        };
      } else return state.failedOrdersSearchData;
    },
    resetState() {
      return initialState;
    },
    setShowOrHideSnackBar(state, showOrHide) {
      state.showOrHideSnackBar = showOrHide.payload;
    },
    setSnackBarMessage(state, message) {
      state.snackBarMessage = message.payload;
    },
  },
});

export const {
  resetState,
  resetSearchData,
  updateFormSearchData,
  setFailedOrderSearchData,
  getFailedOrderSearchData,
  setFailedOrderRequestData,
  getFailedOrderRequestData,
  updateFailedOrdersResponseData,
  resetFailedOrderResponseData,
  setFlag,
  setSidePanel,
  setPageSize,
  setShowOrHideSnackBar,
  setSnackBarMessage,
} = reprocessOrdersApiSlice.actions;

export default reprocessOrdersApiSlice.reducer;

/**  Post Order Request
 * {
  "orderIds": [
    "45a9b054-62c5-11ec-90d6-0242ac120013",
    "45a9b2d4-62c5-11ec-90d6-0242ac120003"
  ],
  "userId": "12345sd",
  "defectId": "OMT-12345",
  "comment": "downstream services are down",
  "orderType": "zsta"
}
 */

const presetDates = [
  "15Min",
  "30Min",
  "1Hr",
  "4Hr",
  "24Hr",
  "Yesterday",
  "last7Days",
  "last15Days",
  "last30Days",
];
const currentDate = new Date();
const calculateDate = (eventName, eventValue) => {
  let finalDate = new Date();
  if (eventName === "presetDate") {
    switch (eventValue) {
      case "15Min":
        finalDate = moment(currentDate).subtract(15, "m").toDate();
        break;
      case "30Min":
        finalDate = moment(currentDate).subtract(30, "m").toDate();
        break;
      case "1Hr":
        finalDate = moment(currentDate).subtract(1, "hours").toDate();
        break;
      case "4Hr":
        finalDate = moment(currentDate).subtract(4, "hours").toDate();
        break;
      case "24Hr":
        finalDate = moment(currentDate).subtract(24, "hours").toDate();
        break;
      case "Yesterday":
        finalDate = moment(currentDate).subtract(1, "day").toDate();
        break;
      case "last7Days":
        finalDate = moment(currentDate).subtract(7, "day").toDate();
        break;
      case "last15Days":
        finalDate = moment(currentDate).subtract(15, "day").toDate();
        break;
      case "last30Days":
        finalDate = moment(currentDate).subtract(30, "day").toDate();
        break;
      default:
        console.log("not parseable date");
        break;
    }
    console.log("final Date: " + finalDate);
  }
  return finalDate;
};
