import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getSyncCall } from '../utils/apiAdapter';

export let initialState = {
    auditFlag : false,
    orderHeaderFlag : false,
    auditSearchCriteria: {searchType: 'Order UUID', searchCriteria: 'Order UUID'},
    auditPageSize : 10,
    orderHeaderPageSize : 10,
    orderHeaderInfoDisplay: {},
    orderSummaryRequestData : {},
    orderSummaryResponseData: {}, 
    orderSummaryError: '', 
    orderHeaderRequest: {},
    orderHeaderResponse : {},
    orderHeaderError : '',
    activeTab: 0
}

export const getOrderUUIDs = createAsyncThunk("auditEventsApiSlice/getOrderUUIDs",async (input,state)=> {

    try{
        //const api ="https://osdordersummary-dev.mp-ordermgmt-test.nikecloud.com/order_mgmt/osd_order_summary/v3/"
        //let api = "https://mp-ordersplithydration-test.nike.com/order_mgmt/order_status_details/v1/"
        let api = 'https://reprocess-api-test.mp-ordermgmt-test.nikecloud.com/order_mgmt/osd_orders/order_hydration/v3/'
        
        const headers= {
            'Authorization' : input["authKey"]
        }
        let reqParams = ''
        if(input['dataType']==="Order UUID"){
            reqParams = 'filter=id('+input['data']+')'
        } else if(input['dataType']==="Customer PONumber"){
            reqParams = 'filter=customerPurchaseOrderNumber(' + input['data'] + ')'
        }
        // input['count'] = input['count'] ? input['count'] : 0;
        // input['page'] = input['page'] ? input['page'] : 10;
        const pager = '&count='+input['count']+'&anchor='+input['page']
        reqParams = reqParams.concat(pager)
        const res = getSyncCall(api, headers,reqParams)
        return res;
    }catch(e){
        console.log(e);
    }
}
);

export const getOrderAuditEvents = createAsyncThunk("auditEventsApiSlice/getOrderAuditEvents", (input) => {
    // const api = baseConfig.api.auditEventApi
    try{
        //const api ="https://microcks.mp-ordermgmt-test.nikecloud.com:8080/rest/STO+Pipeline/1.0.0/audit"
        let api = 'https://mp-osdaudit-test.nike.com/order_mgmt/osd_audit/v1/'
        api = 'https://reprocess-api-test.mp-ordermgmt-test.nikecloud.com/order_mgmt/osd_orders/audit/v3/'
        
        api = api.concat(input['data'])

        const headers= {
            'Authorization' : input['authKey']
        }
        let reqParams = ''
        const pager = 'count='+input['count']+'&anchor='+input['page']
        reqParams = reqParams.concat(pager)

        const res = getSyncCall(api, headers,reqParams)
        return res;
    } catch(e){
        console.log(e);
    }

});

const auditEventsApiSlice = createSlice(
    {
        name: 'auditEventsApiSlice',
        initialState,
            extraReducers : {
                [getOrderUUIDs.pending]() {
                  console.log("pending")
                },
                [getOrderUUIDs.fulfilled] (state,{payload}) {
                  console.log("fulfilled")
                  console.log(payload)
                  state.orderSummaryResponseData = payload
                },
                [getOrderUUIDs.rejected] (state,{payload}){
                  console.log("fetching Errores")
                  state.orderSummaryError = "Rejected"
                  state.orderSummaryResponseData = payload
                },
                [getOrderAuditEvents.pending]() {
                    console.log("pending")
                  },
                [getOrderAuditEvents.fulfilled] (state,{payload}) {
                    console.log("fulfilled")
                    console.log(payload)
                    state.orderHeaderResponse = payload
                },
                [getOrderAuditEvents.rejected] (state,{payload}){
                    console.log("fetching Errores")
                    state.orderHeaderError = "Rejected"
                    state.orderHeaderResponse = payload
                  },
            },
            reducers:{ 
            resetState() {
                return initialState;
            },
            
            updateFormSearchCriteria(state, action) {
                state.auditSearchCriteria[action.payload.key] = action.payload.value; 
            },
            updateOrderSummaryResponseData(state, action) {
                state.orderSummaryResponseData = action; 
            },
            updateOrderHeaderInfoDisplay(state, action){
                state.orderHeaderInfoDisplay = action.payload;
            },
            setActiveTab(state, action) {
                state.activeTab = action.payload;
            },
            setAuditFlag(state, auditFlag) {
                state.auditFlag = auditFlag
            },
            setAuditPageSize(state, auditPageSize) {
                state.auditPageSize = auditPageSize['payload']
            },
            setOrderHeaderFlag(state, orderHeaderFlag) {
                state.orderHeaderFlag = orderHeaderFlag
            },
            setOrderHeaderPageSize(state, orderHeaderPageSize) {
                state.orderHeaderPageSize = orderHeaderPageSize['payload']
            },
            
            setOrderSummaryRequestData(state, requestedData) {
                state.orderSummaryRequestData = {
                    authKey : requestedData["payload"]['authKey'],
                    dataType : requestedData["payload"]['dataType'],
                    data : requestedData["payload"]['data'],
                    page : requestedData["payload"]['page'],
                    count : requestedData["payload"]['count']
                }
            },
            setOrderHeaderRequest(state, requestedData) {
                state.orderHeaderRequest = {
                    authKey : requestedData["payload"]['authKey'],
                    data : requestedData["payload"]['data'],
                    page : requestedData["payload"]['page'],
                    count : requestedData["payload"]['count']
                }
            },
            resetOrderSummaryResponseData(state) {
                state.orderSummaryResponseData={}
            },
        }
        
    }
);

export const {
    resetState, updateFormSearchCriteria, updateOrderSummaryResponseData, updateOrderHeaderInfoDisplay, setActiveTab,
     setOrderSummaryRequestData, setAuditFlag, setOrderHeaderFlag, setOrderHeaderRequest, resetOrderSummaryResponseData, setAuditPageSize, setOrderHeaderPageSize
} = auditEventsApiSlice.actions;

export default auditEventsApiSlice.reducer;
