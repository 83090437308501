import React, { lazy, useEffect , useState} from "react";
import { Icon, Button } from "@nike/eds";

const QualtricsSurvey = () => {
    const [hideSurvey, setHideSurvey] = useState(true);
    
    return (
        <div className={hideSurvey ? 'qualtrics-survey-container qualtrics-survey-container-hide' : 'qualtrics-survey-container'}>
          <div className="qualtrics-survey-wrapper">
            <Button size="small" beforeSlot={<Icon name="Menu" color="white" size="s"/>} className="qualtrics-survey-button" onClick={() => setHideSurvey(!hideSurvey)}>Give Feedback</Button>
            <iframe src="https://nikeemployeevoice.qualtrics.com/jfe/form/SV_2rCJvHShkEb9ioS" className="qualtrics-survey-frame"></iframe>
          </div>
        </div>
    )
    
}

export default QualtricsSurvey;