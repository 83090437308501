import React, { lazy, useEffect, useMemo, useState } from "react";
import { Security, LoginCallback, SecureRoute } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Route, useHistory, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setAuthorizationState,
  setOktaToken,
  setOktaUser,
} from "../_reducer/oktaSlice";
export const getOktaAuth = () => {
  return (oktaAuth.authStateManager && oktaAuth.authStateManager._authState) ? (<div>
    <h5>{oktaAuth.authStateManager._authState.idToken.claims.name}</h5>
    <p>{oktaAuth.authStateManager._authState.idToken.claims.email}</p>
    </div>) : "";
};

export const getOktaAuthUsername = () => {
  return (oktaAuth.authStateManager && oktaAuth.authStateManager._authState) ? oktaAuth.authStateManager._authState.idToken.claims.name : "";
};
const oktaAuth = new OktaAuth({
  issuer: "https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7",
  clientId: "nike.niketech.audit-events-ui",
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: [
    "order_management:cust_mgmt.sto_order.order_id::create:",
    "ordermgmt.osdordersummary.update",
    "ordermgmt.osdordersummary.read",
    "openid",
    "profile",
    "email",
  ],
  // transformAuthState: async (oktaAuth, authState) => {
  //     if (!authState.isAuthenticated) {
  //         return authState;
  //     }
  //     const user = await oktaAuth.token.getUserInfo();
  //     authState.isAuthenticated = !!user;
  //     authState.users = user;
  //     return authState;
  // },
  tokenManager: {
    autoRenew: false,
    storage: "sessionStorage",
  },
  // cookies: {
  //     secure: true
  // },
  pkce: true,
});

const RoutesNew = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setAuthorizationState(
        oktaAuth &&
          oktaAuth.authStateManager &&
          oktaAuth.authStateManager.getAuthState()
      )
    );
    dispatch(setOktaUser(oktaAuth && oktaAuth.getUser()));
    dispatch(setOktaToken(oktaAuth && oktaAuth.getAccessToken()));
  }, [oktaAuth]);

  const history = useHistory();
  let path = window.location.pathname;
  const OrderReprocess = lazy(() => import("../container/orderReprocess"));
  const MetricsDashBoardLevel0 = lazy(() =>
    import("../components/metricsDashboard/dashboardLevels")
  );
  const MetricsDashBoardLevel1 = lazy(() =>
    import("../components/metricsDashboard/metricsDashboardLevel1")
  );
  const MetricsDashBoardLevel2 = lazy(() =>
    import("../components/metricsDashboard/metricsDashboardLevel2")
  );

  const restoreOriginalUri = (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };
  return (
    <>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Switch>
          <Route path="/login/callback" component={LoginCallback} />
          <SecureRoute
            key="/order-reprocess"
            path={["/", "/order-reprocess"]}
            title="Order Reprocess"
            component={OrderReprocess}
            nav={true}
            secure={true}
            breadcrumb="Order Reprocess"
          />
          <SecureRoute
            key="/metrics-dashboard"
            path={["/metrics-dashboard"]}
            title="Metrics Dashboard"
            component={MetricsDashBoardLevel0}
            nav={true}
            secure={true}
            breadcrumb="Metrics Dashboard"
          />
          <SecureRoute
            key={`${path}/level1`}
            path={`${path}/level1`}
            title="Metrics Dashboard Level 1"
            component={MetricsDashBoardLevel1}
            exact
            nav={true}
            secure={true}
            breadcrumb="Metrcs Dashboard Level 1"
          />
          <SecureRoute
            key={`${path}/level2`}
            path={`${path}/level2`}
            title="Metrics Dashboard Level 2"
            component={MetricsDashBoardLevel2}
            exact
            nav={true}
            secure={true}
            breadcrumb="Metrcs Dashboard Level 2"
          />
        </Switch>
      </Security>
    </>
  );
};

export default RoutesNew;

// useEffect(() => {
//     oktaAuth.authStateManager.subscribe(setAuthState);
//     oktaAuth.authStateManager.updateAuthState();
//     //dispatch(setAuthorizationState(oktaAuth.authStateManager.getAuthState()))
//     const interval = window.setInterval(() => oktaAuth.authStateManager.updateAuthState(), 1000);
//     return () => clearInterval(interval);
// }, [oktaAuth]);
// // Handle token retrieval
// useEffect(() => {
//     // Ignore if pending or authenticated
//     if (authState.isPending || authState.isAuthenticated) {
//         return;
//     }
//     // Store tokens if sourced from redirect
//     if (oktaAuth.isLoginRedirect()) {
//         oktaAuth.storeTokensFromRedirect();
//         return;
//     }
//     oktaAuth.token.getWithRedirect();
// }, [authState, oktaAuth]);
// useEffect(() => {
//     if (user || authState.isPending || !authState.isAuthenticated) {
//       return;
//     }
//     oktaAuth
//       .getUser()
//       .then(setUser)
//       .catch((err) => {
//         console.error(err);
//         setUser('ERROR');
//       });
//    // dispatch(setOktaUser(user));
//   }, [user, authState, oktaAuth]);

// oktaAuth.authStateManager.subscribe(authState => {

// });
// oktaAuth.authStateManager.updateAuthState();
