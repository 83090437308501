import axios from "axios";
import { mergeObjects } from "./appUtil";

const initialState = {
    reqHeaders : {
        'Accept' : '*',
        'Access-Control-Allow-Origin' : '*',
    },
    reqData : {},
    reqParams : {}
}

export const getSyncCall = async (url,reqHeaders,reqParams) => {
    reqHeaders = mergeObjects(initialState.reqHeaders,reqHeaders)

    try {
            if(reqParams!==''){
                url = url.concat('?').concat(reqParams)
            }
            const response = await axios.get(url,{ headers : reqHeaders});
            return response.data;
        } catch (errors) { 
            if(!errors.response) {
                throw errors;
            }
            throw errors.response.data;
    }
}

export const getPaginatedSyncCall = async (url,reqHeaders,reqParams) => {
    reqHeaders = mergeObjects(initialState.reqHeaders,reqHeaders)
    console.log(reqHeaders)
    //reqParams = mergeObjects(initialState.reqParams,reqParams)
    console.log(reqParams)
    //reqParams = new URLSearchParams(reqParams);
    //let encoded = encodeURI(url+'?'+reqParams);
    try {
            console.log("getSyncCall Started");
            let response = await axios.get(url+'?'+reqParams,{ headers : reqHeaders});
            let totalPages = response.data['pageTotal']
            let count = response.data['count'];

            if(totalPages>1){
                let addedParams = '&count='+count+'&anchor=0'
                let newParams = reqParams.concat(addedParams)
                response = await axios.get(url+'?'+newParams,{ headers : reqHeaders});
            }
            console.log("getSyncCall completed: ", response.data);
            return response.data;
        } catch (errors) { 
            if(!errors.response) {
                throw errors;
            }
            throw errors.response.data;
    }
}

export const postSyncCall = async (url,reqData,reqHeaders) => {
    reqHeaders = mergeObjects(initialState.reqHeaders,reqHeaders)
    reqData = mergeObjects(initialState.reqData,reqData)
    try {
        const response = await axios.post(url, reqData, {headers : reqHeaders});
        console.log(response);
        return response.data;
    } catch (errors) {
        if(!errors.response) {
            throw errors;
        }
        throw errors.response.data;
    }
}

export const putSyncCall = async (url,reqData,reqHeaders) => {
    reqHeaders = mergeObjects(initialState.reqHeaders,reqHeaders)
    reqData = mergeObjects(initialState.reqData,reqData)
    try {
        const response = await axios.put(url, reqData, {reqHeaders});
        console.log(response);
        return response.data;
    } catch (errors) {
        if(!errors.response) {
            throw errors;
        }
        throw errors.response.data;
    }
}

export const deleteSyncCall = async (url,reqHeaders,reqParams) => {
    reqHeaders = mergeObjects(initialState.reqHeaders,reqHeaders)
    reqParams = mergeObjects(initialState.reqParams,reqParams)
    try {
        const response = await axios.delete(url, {reqHeaders}, {param : reqParams});
        console.log(response);
        return response.data;
    } catch (errors) {
        if(!errors.response) {
            throw errors;
        }
        throw errors.response.data;
    }
}

export const getAsyncCall = async (url,reqHeaders,reqParams) => {
    reqHeaders = mergeObjects(initialState.reqHeaders,reqHeaders)
    reqParams = mergeObjects(initialState.reqParams,reqParams)
    try {
            const response = axios.get(url, { params: reqParams }, { reqHeaders });
            console.log(response);
            return response.data;
        } catch (errors) { 
            if(!errors.response) {
                throw errors;
            }
            throw errors.response.data;
    }
}

export const postAsyncCall = async (url,reqData,reqHeaders) => {
    reqHeaders = mergeObjects(initialState.reqHeaders,reqHeaders)
    reqData = mergeObjects(initialState.reqData,reqData)
    try {
        const response = axios.post(url, reqData, {reqHeaders});
        console.log(response);
        return response.data;
    } catch (errors) {
        if(!errors.response) {
            throw errors;
        }
        throw errors.response.data;
    }
}

export const putAsyncCall = async (url,reqData,reqHeaders) => {
    reqHeaders = mergeObjects(initialState.reqHeaders,reqHeaders)
    reqData = mergeObjects(initialState.reqData,reqData)
    try {
        const response = axios.put(url, reqData, {reqHeaders});
        console.log(response);
        return response.data;
    } catch (errors) {
        if(!errors.response) {
            throw errors;
        }
        throw errors.response.data;
    }
}

export const deleteAsyncCall = async (url,reqHeaders,reqParams) => {
    reqHeaders = mergeObjects(initialState.reqHeaders,reqHeaders)
    reqParams = mergeObjects(initialState.reqParams,reqParams)
    try {
        const response = axios.delete(url, {reqHeaders}, {param : reqParams});
        console.log(response);
        return response.data;
    } catch (errors) {
        if(!errors.response) {
            throw errors;
        }
        throw errors.response.data;
    }
}