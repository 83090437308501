import React, { useState } from "react";
// import { Link, useHistory } from "react-router-dom";
import { Icon } from "@nike/eds";
import RoutesNew from "../../../_routes/routesNew";
import { NikeApp } from "@nike/nike-design-system-icons";
// import styles from "./Main.module.css";
import "./Main.css";

import {
  AppHeader,
  IconButton,
} from "@nike/eds";
import QualtricsSurvey from "../../../container/qualtricsSurvey";
import SnackbarLoader from "../../../container/snackbarLoader";
import UserSidePanel from "../../../container/userSidePanel";
import { useDispatch } from "react-redux";
import { setSidePanel } from "../../../_reducer/reprocessOrdersApiSlice";

const Main = (props) => {
  // const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  return (
    <>
      <div className="mainContainer">
        <div className="appContainer">
          <div>
            <AppHeader
              style={{ backgroundColor: "#111111" }}
              logoSlot={<NikeApp color={"white"} width={"56px"} height={"64px"} />}
              appName="Order Reprocess Production Tool"
              toolbarSlot={<h2 className="app-header-region" style={{ fontSize: '100%' }}><span>Region:</span> <span>GC</span></h2>}
              avatarSlot={(
                <IconButton icon={<Icon name="ProfileFilled" variant="secondary" />} onClick={() => { dispatch(setSidePanel(true)) }} />
              )}
            />
          </div>
          <RoutesNew />
        </div>
        <QualtricsSurvey />
        <SnackbarLoader />
        <UserSidePanel />
      </div>
    </>
  );
};

export default Main;
