import {
    Snackbar,
    Snack,
    Spinner,
} from "@nike/eds";
import { useSelector } from "react-redux";

const SnackbarLoader = () => {
    const loading = useSelector((state) => {
        return state.dashboardSliceReducer.loading;
    });
    return (
        <>
            {loading && (
                <div id="axios-processing" className="processing-container">
                    <div className="processing-wrapper">
                        <Spinner /> Processing...
                        </div>
                </div>
            )}
        </>
    )
}
export default SnackbarLoader;