import React from "react";
import { getOktaAuth } from "../_routes/routesNew";
import { SidePanel } from "@nike/eds";
import { useDispatch, useSelector } from "react-redux";
import { setSidePanel } from "../_reducer/reprocessOrdersApiSlice";

const UserSidePanel = (props) => {
	const dispatch = useDispatch();
	const open = useSelector((state) => {
		return state.reprocessReducer.sidePanel;
	});
	return (
		<SidePanel
			onDismiss={() => dispatch(setSidePanel(false))}
			isOpen={open}
			headerSlot={
				<>
					<h4>Profile</h4>
					<hr />
				</>
			}
		>
			{getOktaAuth()}
		</SidePanel>
	);
};

export default UserSidePanel;
