export const orderTypes = ['ZCON','ZDIG','ZFIN','ZFRE','ZRFR','ZRST','ZSTA','ZSYS','ZCSG','ZTRD','ZS03','ZS04','ZS05','ZS06','ZS09','ZS10']

export const poTypes = ['API','C3PO','CLLB','CONV','DIGI','ECS','EDI','EXTF','GSM','INTF','JES','MANL','NBY','NBYE','NET','NTSB','SMT','UII','WRSO']

export const salesOrgTypes = ['9600','9700','9800']

export const reportType = {
    "IN_PROGRESS" : "In Progress",
    "SENT_EXCEPTION": "Exception",
    "SENT_DLQ": "Exception",
    "SUBMITTED_SCPI": "Submitted to SCPI",
}

// export const apiSource = "https://reprocess-api-prod.mp-ordermgmt-prod.nikecloud.com"; // PROD
export const apiSource = "https://reprocess-api-qa-sierra.mp-ordermgmt-test.nikecloud.com"; //QA2
