import React from 'react';

const ResponseProcessorUtil = (props) => {
    
    const response = {
        "received": [
            {
                "orderType": "ZFRE",
                "count": 5
            },
            {
                "orderType": "ZCON",
                "count": 10
            },
            {
                "orderType": "ZSTA",
                "count": 15
            }
        ],
        "overallStatus": [
            {  
                "status": "exception",
                "data": [
                    {
                "orderType": "ZFRE",
                "count": 20
            },
            {
                "orderType": "ZCON",
                "count": 5
            },
            {
                "orderType": "ZSTA",
                "count": 35
            }
                ],
                "total" : 60
            },
            {  
                "status": "IN_PROGRESS",
                "data": [
                    {
                "orderType": "ZFRE",
                "count": 10
            },
            {
                "orderType": "ZCON",
                "count": 0
            },
            {
                "orderType": "ZSTA",
                "count": 35
            }
                ],
                "total" : 45
            }
        ],
        "split": [],
        "dlq": [],
        "exception": [
            {
                "orderType": "ZFRE",
                "count": 12
            },
            {
                "orderType": "ZCON",
                "count": 2
            },
            {
                "orderType": "ZSTA",
                "count": 5
            }
        ]
    }
    }   

export const calculateCountByScenario = (response) => {
    let finalResponseObject = {}
    finalResponseObject['received'] = response.received.reduce((obj1, obj2) => obj1.count+obj2.count)
    finalResponseObject['overallStatus'] = response.overallStatus.reduce((obj1,obj2) => obj1.count+obj2.count)
    finalResponseObject['split'] = response.split.reduce((obj1,obj2) => obj1.count+obj2.count)
    finalResponseObject['dlq'] = response.dlq.reduce((obj1,obj2) => obj1.count+obj2.count)
    finalResponseObject['exception'] = response.exception.reduce((obj1,obj2) => obj1.count+obj2.count)
    return finalResponseObject;
}

export default ResponseProcessorUtil;
