import React, { Suspense, useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import store from "./_store/store";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import Main from "./components/metricsDashboard/Main/Main";

let persistor = persistStore(store);
ReactDOM.render(
  <div style={{}}>
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Suspense fallback={<div>Fetching....</div>}>
              <Main />
              {/* <PodiumMain/> */}
            </Suspense>
          </Router>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </div>,
  document.getElementById("root")
);