import { configureStore } from '@reduxjs/toolkit';
import auditEventsApiSlice from '../_reducer/auditEventsApiSlice';
import reprocessOrdersApiSlice from '../_reducer/reprocessOrdersApiSlice';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux';
import appSlice from '../_reducer/appSlice';
import storage from 'redux-persist/lib/storage';
import dashboardSlice from '../_reducer/dashboardSlice';
import OktaSlice from '../_reducer/oktaSlice';

const reducers = combineReducers({
    reprocessReducer : reprocessOrdersApiSlice,
    appSliceReducer: appSlice,
    auditEventsApiSliceReducer: auditEventsApiSlice,
    dashboardSliceReducer : dashboardSlice,
    oktaSliceReducer: OktaSlice
  });
  const persistConfig = {
    key: 'root',
    storage,
  };
  const persistedReducer = persistReducer(persistConfig, reducers);


export default configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
});

