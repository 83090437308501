
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { calculateCountByScenario } from '../components/utils/responseProcessorUtil';
import { postSyncCall } from '../utils/apiAdapter';

const initialState = {
    requestData: {},
    responseData: [],
    selectedOption: 'Select Search Option',
    orderSummaryDetailsResponse: {},
    dashboardResponseLevel0: {},
    dashboardResponseLevel1: {},
    dashboardResponseLevel2: {},
    chartOrderType: 'Select Order Type',
    dashBoardStatsCount: {},
    metricsFilterSearchRequest: {
        count: 10,
        anchor: 0
    },
    pieStats: {},
    byOrderType: {},
    searchModal: false,
    levelSelected: '0',
    selectedSearchTopicMessage: '',
    selectedKey: "",
    orderTypeData: {}
}

// export const fetchForSearchCriteriaOFOA = createAsyncThunk("dashboardSlice/fetchMetricsForOFOA", (data) => {
//     try {
//         return postSyncCall(api, data.payload, null);
//     } catch (e) {
//         console.log(e);
//     }
// })

// export const fetchOrderSummaryDetails = createAsyncThunk("dashboardSlice/fetchOrderSummaryDetails", (data) => {
//     try{
//         let query = "";
//         const api = "order summary api";
//         switch(data.payload.key) {
//             case 'received' : 
//                 query = "select query 1";
//                 break;
//             case 'inProgress': 
//                 query = 'select query for order in status as in progress';
//                 break;
//             case 'splitOrdersCreated':
//                 query = 'select query for split order flag';
//                 break;
//             case 'submittedToSCPI':
//                 query = 'select query for order submitted to scpi';
//                 break;
//             case 'sentToDLQ':
//                 query = 'select query to sentToDLQ';
//                 break;
//             case 'sentToException':
//                 query = 'select query to sentToDLQ';
//                 break;
//             case 'receivedByOrderType':
//                 query = 'select query to sentToDLQ';
//                 break;
//             case 'healthSystem': break;
//             default: console.log('in default, exiting'); break;
//         }
//         return postSyncCall(api, query, null);
//     }catch(e) {
//         console.log(e);
//     }
// });



export const fetchForSearchCriteriaOFOA = createAsyncThunk("dashboardSlice/fetchForSearchCriteriaOFOA", async (_, { getState, dispatch }) => {
    //common method for all the three levels this will be called post metricsFilterSearchRequest creation
    const root = getState()
    const metricsFilterSearchRequest = root.dashboardSliceReducer.metricsFilterSearchRequest;
    const level = metricsFilterSearchRequest.level;
    console.log(metricsFilterSearchRequest);
    let api = 'https://osd-ops-dashboard-dev.mp-ordermgmt-test.nikecloud.com/ops-dashboard/summary/stats/'
    //let api = 'http://localhost:8080/ops-dashboard/summary/stats'
    
    if (level === '0' || level === '2') {
        return postSyncCall(api, metricsFilterSearchRequest, null);
    }
    if (level === '1') {
        return postSyncCall(api, metricsFilterSearchRequest, null);
    }
    if (level === '2') {
        metricsFilterSearchRequest = { ...metricsFilterSearchRequest }
        return postSyncCall(api, metricsFilterSearchRequest, null);
    }
});

// metrics infor for Level 0
export const getOrderSummaryReport = createAsyncThunk("dashboardSlice/getOrderSummaryReport", (data) => {
    try {
        let requestedData = {}
        requestedData.orderIds = data && data.orderId && data.orderId.includes(",") ? data && data.orderId && data.orderId.split(",") : [].concat(data.orderId);
        requestedData.poNumbers = data && data.poNumber && data.poNumber.includes(",") ? data && data.poNumber && data.poNumber.split(",") : [].concat(data.poNumber);
        let api = 'https://osd-ops-dashboard-dev.mp-ordermgmt-test.nikecloud.com/ops-dashboard/summary/v1/';
        const response =  postSyncCall(api, requestedData, null)
        return calculateCountByScenario(response);
    } catch (e) {
        console.log(e);
    }
});
const dashboardslice = createSlice(
    {
        name: 'dashboardSlice',
        initialState,
        extraReducers: {
            [getOrderSummaryReport.pending]() {
                console.log("getOrderSummaryReport pending")
            },
            [getOrderSummaryReport.fulfilled](state, { payload }) {
                console.log("getOrderSummaryReport fulfilled")
                console.log(payload)
                state.responseData = payload
            },
            [getOrderSummaryReport.rejected](state) {

                console.log("getOrderSummaryReport fetching Errores: " + state)
                state.error = "Rejected"
            },
            [fetchForSearchCriteriaOFOA.pending]() {
                console.log("fetchForSearchCriteriaOFOA pending")
            },
            [fetchForSearchCriteriaOFOA.fulfilled](state, { payload }) {
                console.log("fetchForSearchCriteriaOFOA fulfilled")
                console.log(payload)
                if (state.levelSelected === '0') {
                    state.dashboardResponseLevel0 = payload
                }
                else if (state.levelSelected === '1') {
                    state.dashboardResponseLevel1 = payload
                }
                else if (state.levelSelected === '2') {
                    state.dashboardResponseLevel2 = payload
                }
            },
            [fetchForSearchCriteriaOFOA.rejected](state) {
                console.log("fetchForSearchCriteriaOFOA fetching Errores")
                state.error = "Rejected"
            }
        },
        reducers: {
            updateSelectedKeyTopic(state, data) {
                state.selectedSearchTopicMessage = data.payload
            },
            toggleSearchModal(state) {
                state.searchModal = state.searchModal ? false : true
            },
            resetSearch(state) {
                state.requestData = {}
            },
            resetState(state) {
                return initialState
            },
            resetLevelGrid(state) {
                state.orderSummaryDetailsResponse = {}
            },
            resetGrid(state) {

                state.responseData = []
            },
            setSelectedOption(state, option) {
                state.selectedOption = option.payload;
            },
            resetSelectOption(state) {
                state.selectedOption = 'Select Search Option'
            },
            updateLevel(state, level) {
                state.levelSelected = level.payload
                state.metricsFilterSearchRequest = { ...state.metricsFilterSearchRequest, level: level.payload }
            },
            updateLevelOnBackClick(state, level) {
                state.levelSelected = level.payload
                
                state.metricsFilterSearchRequest = { ...state.metricsFilterSearchRequest, level: level.payload, orderId: undefined, poNumber: undefined }
                // state = {...state}
            },
            updatePieStats(state, data) {
                state.pieStats = data.payload
            },
            //pagination logic
            updatePageNum(state, data) {
                let metricsFilterSearchRequest = state.metricsFilterSearchRequest;
                const pageData = data.payload;
                if (pageData) {
                    return { ...metricsFilterSearchRequest, count: pageData.count, anchor: pageData.anchor }
                }
            },
            //this is when user clicks on a row
            updateRowOrderId(state, data) {
                let metricsFilterSearchRequest = state.metricsFilterSearchRequest;
                if (state.levelSelected === 'Level 1') {
                    return { ...metricsFilterSearchRequest, orderId: data.payload }
                }
                if (state.levelSelected === 'Level 2') {
                    return { ...metricsFilterSearchRequest, orderId: data.payload }
                }
            },
            updatePieOrderType(state, data) {
                state.pieOrderType = data.payload
            },
            updateSelectedKey(state,key) {
                state.selectedKey = key.payload
            },
            resetresponseLevel1(state) {
                state.dashboardResponseLevel1 = undefined
            },
            //search filter request
            createSearchRequest(state, data) {
                const level = state.levelSelected;
                const filterSearchRequest = data && data.payload;
                let metricsFilterSearchRequest = {};
                let oldMetricsFilterSearchRequest = {};
                metricsFilterSearchRequest = { ...state.metricsFilterSearchRequest }

                if (filterSearchRequest) {
                    
                    metricsFilterSearchRequest = filterSearchRequest.metricsFilterSearchRequest
                    metricsFilterSearchRequest = { ...metricsFilterSearchRequest, level: filterSearchRequest && filterSearchRequest['level'] ? filterSearchRequest['level'] : level }
                    if(filterSearchRequest.startDate) {
                        metricsFilterSearchRequest = { ...metricsFilterSearchRequest, startTime: filterSearchRequest['startDate'].concat(":00") };
                    }
                    
                    if(filterSearchRequest.endDate) {
                        metricsFilterSearchRequest = { ...metricsFilterSearchRequest, endTime: filterSearchRequest['endDate'].concat(":00") };
                    }
                    else if(!filterSearchRequest.endDate && metricsFilterSearchRequest.endTime) {
                        delete metricsFilterSearchRequest.endTime
                    }
                    if(filterSearchRequest.orderType && filterSearchRequest.orderType!=='Select Order Type') {
                        metricsFilterSearchRequest = { ...metricsFilterSearchRequest, orderType: filterSearchRequest.orderType };
                    }
                    else if(filterSearchRequest.orderType && filterSearchRequest.orderType==='Select Order Type' && metricsFilterSearchRequest.orderType) {
                        delete metricsFilterSearchRequest.orderType
                    }
                    if(filterSearchRequest.poType && filterSearchRequest.poType!=='Select PO Type') {
                        metricsFilterSearchRequest = { ...metricsFilterSearchRequest, poType: filterSearchRequest.poType };
                    }
                    else if(filterSearchRequest.poType && filterSearchRequest.poType==='Select PO Type' && metricsFilterSearchRequest.poType) {
                        delete metricsFilterSearchRequest.poType
                    }
                    if(filterSearchRequest.salesOrg && filterSearchRequest.salesOrg!=='Select SalesOrg Type') {
                        metricsFilterSearchRequest = { ...metricsFilterSearchRequest, salesOrg: filterSearchRequest.salesOrg };
                    }
                    else if(filterSearchRequest.salesOrg && filterSearchRequest.salesOrg==='Select SalesOrg Type' && metricsFilterSearchRequest.salesOrg) {
                        delete metricsFilterSearchRequest.salesOrg
                    }
                    if(filterSearchRequest.orderId) {
                        metricsFilterSearchRequest = { ...metricsFilterSearchRequest, orderId: filterSearchRequest.orderId };
                    }
                    else if(!filterSearchRequest.orderId && metricsFilterSearchRequest.orderId) {
                        delete metricsFilterSearchRequest.orderId
                    }
                    if(filterSearchRequest.poNumber) {
                        metricsFilterSearchRequest = { ...metricsFilterSearchRequest, poNumber: filterSearchRequest.poNumber };
                    }
                    else if(!filterSearchRequest.poNumber && metricsFilterSearchRequest.poNumber) {
                        delete metricsFilterSearchRequest.poNumber
                    }
                    if (state.selectedKey) {
                        metricsFilterSearchRequest = { ...metricsFilterSearchRequest, reportType: state.selectedKey  }
                    }
                    else if(filterSearchRequest.reportType) {
                        metricsFilterSearchRequest = { ...metricsFilterSearchRequest, reportType: filterSearchRequest.reportType  }
                    }
                    else {
                        delete metricsFilterSearchRequest.reportType
                    }
                    if (filterSearchRequest.anchor !== undefined) {
                        metricsFilterSearchRequest.anchor && delete metricsFilterSearchRequest.anchor
                        metricsFilterSearchRequest = { ...metricsFilterSearchRequest, anchor: filterSearchRequest.anchor }
                    }
                    metricsFilterSearchRequest = {...metricsFilterSearchRequest, count: 10}
                }

                state.metricsFilterSearchRequest = metricsFilterSearchRequest;
            },
            updateOrderTypeData (state, data) {
                state.orderTypeData = data.payload
            }
        }

    }
);

export const { updateOrderTypeData, resetresponseLevel1, updateSelectedKey, updateSelectedKeyTopic, updateLevelOnBackClick, updatePieOrderType, updatePieStats, toggleSearchModal, resetState, resetLevelGrid, resetGrid, resetSearch, setSelectedOption, resetSelectOption, updateLevel, updateRowOrderId, updatePageNum, createSearchRequest } = dashboardslice.actions;
export default dashboardslice.reducer;